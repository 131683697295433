<template>
  <div>
    <el-row :gutter="10">
      <el-col
        v-if="!typeHidden"
        :xl="{span: 8}"
        :lg="{span: 8}"
        :md="{span: 8}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <el-form-item
          :label="typeLabel?typeLabel:$t('forms_flujo.form1_persona.SP.tipo_doc')"
          :prop="prop+'.document'"
        >
          <el-select
            ref="document"
            v-model="tipo_documento"
            clearable
            filterable
            :placeholder="typeLabel?typeLabel:$t('forms_flujo.form1_persona.SP.tipo_doc')"
            :disabled="numberHidden?'disabled':false"
            @change="handleChange"
          >
            <el-option
              v-for="item in listDocuments"
              :key="item.id"
              :label="item.documento"
              :value="item.id"
            >
              <span>{{ item.documento +' ' }}<span
                style="color: #8492a6;"
              >{{ item.nombre }}</span>
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        :xl="{span:typeHidden?24: 16}"
        :lg="{span:typeHidden?24: 16}"
        :md="{span:typeHidden?24: 16}"
        :sm="{span:24}"
        :xs="{span:24}"
      >
        <el-form-item
          :label="numberLabel?numberLabel:$t('forms_flujo.form1_persona.SP.identity_doc')"
          :prop="prop+'.number'"
          :error="errors"
          :rules="rule"
        >
          <el-input
            ref="number"
            v-model="numero_documento"
            :disabled="numberHidden"
            :type="'text'"
            :placeholder="numberLabel?numberLabel:$t('forms_flujo.form1_persona.SP.identity_doc')"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {isEmpty} from "@/commons/utils/functions";
import {getDocumentsType} from "@/routes/api/resources";
import {required} from "@/commons/utils/Rules";

export default {
    name: "PzIdentityDocument",
    components: {},
    props: {
        prop: {default: null, type: String},
        value: {
            type: [Object],
            default() {
                return {
                    document: '',
                    number: ''
                }
            }
        },
        typeHidden: {type: Boolean, default: false},
        typeLabel: {type: String, default: null},
        numberLabel: {type: String, default: null},
        type: {type: String, default: 'person'},
        numberHidden: {type: Boolean, default: false},
        noRequerido: {type: Boolean, default: false},
        errors: {type: String,default: null},
        typeActive: {type: Boolean, default: true},
    },
    data() {
        var check_doc = (rule, value, callback) => {

            if(!this.regla)
                return callback();

            let pattern = new RegExp(this.regla.toString());
            if (pattern.test(value)) {
                return callback();
            }
            return callback(new Error(this.$t('rules.identity_rule')));

        };
        return {
            listDocuments: [],
            tipo: '',
            numero_documento: '',
            tipo_documento: '',
            regla: '',
            rule: [ {validator: check_doc, trigger: 'blur'}]

        }

    },
    watch: {
        value(val) {
            this.setTipoDocumento(val);
            this.setNumeroDocumento(val);
        },
        type(val) {
            this.setTipo(val);
        },
        // eslint-disable-next-line no-unused-vars
        numero_documento(val) {
            this.handleChange();
        },
        // eslint-disable-next-line no-unused-vars
        tipo_documento(val) {
            this.handleChange();
        },

    },
    created() {
        if(!this.noRequerido)
            this.rule.push(required(''));

        this.$store.dispatch('loader/up', {trigger: this.$options.name});
        this.setTipoDocumento(this.value);
        this.setNumeroDocumento(this.value);
        this.setTipo(this.type);
        let doc = this.documentsType();
        Promise.all([doc]).finally(() => {
            this.$store.dispatch('loader/down', {trigger: this.$options.name})
        });
    },
    methods: {

        handleChange() {
            const value = this.getInputValue();
            const key = this.getInputKey();
            if(this.listDocuments.length > 0 && key) {
                this.regla = this.listDocuments.find(datos => datos.id == key);
                this.regla = this.regla.rules;
            }
            let docs = {document: key, number: value}
            this.$emit('input', docs);
        },
        documentsType() {
            //if (!this.typeHidden) {
            console.log('active '+this.typeActive);
                this.$store.dispatch('loader/up', {trigger: this.$options.name});
                getDocumentsType(this.tipo,this.typeActive).then((res) => {
                    this.listDocuments = res.data;
                    if (this.listDocuments.length === 1) {
                        this.tipo_documento = this.listDocuments[0].id;
                        this.regla = this.listDocuments[0].rules;
                    } else {
                        const self = this;
                        let found = null;
                        if (!isEmpty(this.value.document)) {
                            found = this.listDocuments.filter(function (value) {
                                return (value.id).toString() === (self.value.document).toString();
                            })
                        }
                        if (isEmpty(found)) {
                            this.tipo_documento = this.listDocuments[0].id;
                        }
                    }
                    this.handleChange();
                }).catch().
                    finally(() => {
                        this.$store.dispatch('loader/down', {trigger: this.$options.name})
                    });
           // }
        },
        getInputKey() {
            return this.tipo_documento;
        },
        getInputValue() {
            return this.numero_documento;
        },
        setTipoDocumento(value) {
            const input = this.getInputValue();
            if (input === value.document) return;
            this.tipo_documento = value.document;
        },
        setNumeroDocumento(value) {
            const input = this.getInputKey();
            if (input === value.number) return;
            this.numero_documento = value.number;
        },
        setTipo(value) {
            const input = this.tipo;
            if (input === value) return;
            this.tipo = value;
        },

    }
    ,

}

</script>

<style scoped>
  .disabled {
    pointer-events:none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    background-color: #eef1f6;
    border-color: #d1dbe5;
  }
</style>